import ApiService from "../services/api.service";

const baseUrl = "transaction";
const urlGetList = "list";
const urlElastic = "elastic";

const urlGetDetail = "detail";
const urlDoSuccessPaymentWaitToReview = "/do-success";
const urlAddWhiteListVisaCard = "/visa/add-white-list";
const urlOffsetWallet = "/offset";
const urlWaitToRefund = "/wait-to-refund";
const urlconfirmRefundGate = "/confirm-refund-gate";
const urlCancelRefundGate = "/cancel-refund-gate";
const urlPartnerDetail = "partner-detail";
const urlGetSyncStatus = "list-sync-status";
const urlSyncStatus = "/action-sync-status";
const urlRetryOrder = "/retry-order";
const urlRefundFinancial = "/refund-financial";
const urlReverse = "/reverse-financial";
const urlWithVoicher = "with-voucher";
const urlExportWithVoicher = "with-voucher/export";
const urlApproveDepositTransfer = '/approve-deposit-transfer';
const urlRejectDepositTransfer = '/reject-deposit-transfer';
const urlWalletCharge = '/wallet-charge-deposit-transfer';
const urlChangeOrderStatus = '/change-order-status';
const urlDoReverse = "/reverse";
const urlpushPoint = "/push-point";
const urlSyncOrder = "/sync-order-status";
const urlUpdateActionSuccess = "/update-action-success";
const urlUpdateTransactionSuccess = "/update-transaction-success";
const urlOrderCashback = "/order-cashback";

export default {
  getListElastic(params) {
    return ApiService.get(baseUrl, urlElastic, params);
  },
  getList(params) {
    return ApiService.get(baseUrl, urlGetList, { params: params });
  },
  getDetail(params) {
    return ApiService.get(baseUrl, urlGetDetail, { params: params });
  },
  doSuccessPaymentWaitToReview(params) {
    return ApiService.post(baseUrl + urlDoSuccessPaymentWaitToReview, params);
  },
  addWhiteListPaymentInternational(params) {
    return ApiService.post(urlAddWhiteListVisaCard, params);
  },
  offsetWallet(params) {
    return ApiService.post(baseUrl + urlOffsetWallet, params);
  },
  changeStatusWaitToRefund(params) {
    return ApiService.post(baseUrl + urlWaitToRefund, params);
  },
  confirmRefundGate(params) {
    return ApiService.post(baseUrl + urlconfirmRefundGate, params);
  },
  cancelRefundGate(params) {
    return ApiService.post(baseUrl + urlCancelRefundGate, params);
  },
  getPartnerDetail(params) {
    return ApiService.get(baseUrl, urlPartnerDetail, params);
  },
  getSyncStatus(params) {
    return ApiService.get(baseUrl, urlGetSyncStatus, params);
  },
  syncStatus(params) {
    return ApiService.post(baseUrl + urlSyncStatus, params);
  },
  retryOrder(params) {
    return ApiService.post(baseUrl + urlRetryOrder, params);
  },
  syncOrder(params) {
    return ApiService.post(baseUrl + urlSyncOrder, params);
  },
  refundFinancialPayment(params) {
    return ApiService.post(baseUrl + urlRefundFinancial, params);
  },
  reversePayDebtMcredit(params) {
    return ApiService.post(baseUrl + urlReverse, params);
  },
  transactionWithVoucher(params) {
    return ApiService.get(baseUrl, urlWithVoicher, params);
  },
  exportTransactionWithVoucher(params) {
    return ApiService.get(baseUrl, urlExportWithVoicher, params);
  },
  approveDepositTransfer(params) {
    return ApiService.post(baseUrl + urlApproveDepositTransfer, params);
  },
  rejectDepositTransfer(params) {
    return ApiService.post(baseUrl + urlRejectDepositTransfer, params);
  },
  walletCharge(params) {
    return ApiService.post(baseUrl + urlWalletCharge, params);
  },
  changeOrderStatus(params) {
    return ApiService.post(baseUrl + urlChangeOrderStatus, params);
  },
  doReverse(params) {
    return ApiService.post(baseUrl + urlDoReverse, params);
  },
  doUpdateActionSuccess(params) {
    return ApiService.post(baseUrl + urlUpdateActionSuccess, params);
  },
  doUpdateTransactionSuccess(params) {
    return ApiService.post(baseUrl + urlUpdateTransactionSuccess, params);
  },
  doCashbackOrder(params) {
    return ApiService.post(baseUrl + urlOrderCashback, params);
  },
  doPushPoint(params) {
    return ApiService.post(baseUrl + urlpushPoint, params);
  },
  listGames() {
    return ApiService.get(baseUrl, 'list-games');
  },
  listCamps() {
    return ApiService.get(baseUrl , 'list-camps');
  },
};
