import ApiService from "../services/api.service";

const baseUrl = "";

export default {
    getClients() {
        return ApiService.get(baseUrl+'/combo-voucher/client');
    },
    getEvents(params) {
        return ApiService.get(baseUrl, 'combo-voucher/event', params );
    },
    getEvent(id) {
        return ApiService.get(baseUrl, 'combo-voucher/event/'+id);
    },
    removeEvent(id) {
        return ApiService.delete(baseUrl+'combo-voucher/event/'+id, );
    },

    /*SCRIPT*/
    getDataCreate() {
        return ApiService.get(baseUrl+'/combo-voucher/script/data-to-create');
    },
    getScripts(params) {
        return ApiService.get(baseUrl, 'combo-voucher/script', params );
    },
    getScript(id) {
        return ApiService.get(baseUrl, 'combo-voucher/script/'+id);
    },
    removeScript(id) {
        return ApiService.delete(baseUrl+'combo-voucher/script/'+id);
    },
    storeScript(params) {
        return ApiService.post(baseUrl+'combo-voucher/script',params);
    },
    updateScript(id,params) {
        return ApiService.post(baseUrl+'combo-voucher/script/'+id, params);
    },

    /**REPORT*/
    reportLogUserGifts(params) {
        return ApiService.get(baseUrl, 'combo-voucher/report/log-user-gifts', params);
    },
    exportReportLogUserGifts(params) {
        return ApiService.get(baseUrl, 'combo-voucher/report/export-log-user-gifts', params);
    },
    reportEventVoucherRelease(params) {
        return ApiService.get(baseUrl, 'combo-voucher/report/gift-release', params);
    },

    reportSeedingCampaignStudent(params) {
        return ApiService.get(baseUrl, 'campaign/report/seeding-student', params);
    },


    getListComboVoucherWL(params){
        return ApiService.get(baseUrl,'combo-voucher/whitelist',params);
    },
    getComboVoucherWL(id){
        return ApiService.get(baseUrl,'combo-voucher/whitelist/'+id);
    },
    removeComboVoucherWL(id){
        return ApiService.delete(baseUrl+'combo-voucher/whitelist/'+id,[]);
    },

    /**REFER V2*/
    loadReferInfo(params) {
        return ApiService.get(baseUrl, 'refer', params);
    },
    updateReferInfo(params) {
        return ApiService.post(baseUrl+'refer/config', params);
    },

    getListAllScripts(params) {
        return ApiService.get(baseUrl, 'combo-voucher/script/list-all-script', params);
    },


    /**REFER  STUDENT V2*/
    loadReferStudentInfo(params) {
        return ApiService.get(baseUrl, 'refer-student', params);
    },
    updateReferStudentInfo(params) {
        return ApiService.post(baseUrl+'refer-student',  params);
    },


};
