import ApiService from "../services/api.service";

const baseUrl = "black-list";

const urlGetList = "index";
const urlAdd = "/add";
const urlRemove = "/remove";

export default {
  getList(params) {
    return ApiService.get(baseUrl, urlGetList, { params: params });
  },
  add(params) {
    return ApiService.post(baseUrl + urlAdd, params);
  },
  remove(params) {
    return ApiService.post(baseUrl + urlRemove, params);
  },
};
