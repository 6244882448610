import ApiService from "../services/api.service";

const baseUrl = "campaign";
const listMission = "mission/listMission";
const storeMission = "/mission/store";
const updateMission = "/mission/update/";
const getOptions = "mission/getOptions";
const getMission = "mission/view/";
const deleteMission = "/mission/delete/";
//challenge
const listChallenge = "challenge/listChallenge";
const listAllChallenge = "challenge/listAllChallenge";
const storeChallenge = "/challenge/store";

export default {
  getList(params) {
    return ApiService.get(baseUrl, listMission, params );
  },
  createMission(params) {
    return ApiService.post(baseUrl + storeMission, params);
  },

  updateMission(id, params) {
    return ApiService.post(baseUrl + updateMission + id, params);
  },

  getOptions() {
    return ApiService.get(baseUrl, getOptions, {});
  },
  getMission(id) {
    return ApiService.get(baseUrl, getMission + id, {});
  },

  deleteMission(id) {
    return ApiService.delete(baseUrl + deleteMission + id, {});
  },
  //getListChallenge
  getListChallenge(params) {
    return ApiService.get(baseUrl, listChallenge, params );
  },

  //listAllChallenge
  getListAllChallenge(params=[]) {
    return ApiService.get(baseUrl, listAllChallenge, params );
  },

  createChallenge(params) {
    return ApiService.post(baseUrl + storeChallenge, params);
  },

  updateChallenge(id, params) {
    return ApiService.post(baseUrl + '/challenge/update/' + id, params);
  },

  getOptionsVoucher() {
    return ApiService.get(baseUrl, 'challenge/getVoucherOptions', {});
  },
  getChallenge(id) {
    return ApiService.get(baseUrl, 'challenge/view/' + id, {});
  },

  deleteChallenge(id) {
    return ApiService.delete(baseUrl + '/challenge/delete/' + id, {});
  },

  //combo voucher
  getListCombo(params) {
    return ApiService.get(baseUrl,'combo/listCombo', params );
  },
  createCombo(params) {
    return ApiService.post(baseUrl + '/combo/store', params);
  },

  updateCombo(id, params) {
    return ApiService.post(baseUrl + '/combo/update/' + id, params);
  },


  getCombo(id) {
    return ApiService.get(baseUrl, 'combo/view/' + id, {});
  },

  deleteCombo(id) {
    return ApiService.delete(baseUrl + '/combo/delete/' + id, {});
  },

  getSetTings() {
    return ApiService.get(baseUrl+'/setting/getSettingWlMission');
  },
  storeSetTings(params) {
    return ApiService.post(baseUrl+'/setting/updateSettingWlMission',params);
  },
  reportChallenges(params) {
    return ApiService.get(baseUrl, 'report/challenges', params );
  },
  reportChallengeProcess(id) {
    return ApiService.get(baseUrl, 'report/challenge-process/'+id);
  },
  reportMissions(params) {
    return ApiService.get(baseUrl, 'report/missions', params );
  },
  retryBonus(id) {
    return ApiService.post(baseUrl+'/report/retry/'+id);
  },
  reportGifts(params) {
    return ApiService.get(baseUrl, 'report/challenges-gift-release', params );
  },
  getCategoryList() {
    return ApiService.get(baseUrl, 'challenge/getCategories', {});
  },
  //combo category
  getListCategory(params) {
    return ApiService.get(baseUrl,'category', params );
  },
  createCategory(params) {
    return ApiService.post(baseUrl + '/category', params);
  },

  updateCategory(id, params) {
    return ApiService.post(baseUrl + '/category/' + id, params);
  },


  getCategory(id) {
    return ApiService.get(baseUrl, 'category/' + id, {});
  },

  deleteCategory(id) {
    return ApiService.delete(baseUrl + '/category/' + id, {});
  },

  getRankConfig() {
    return ApiService.get(baseUrl, 'setting/rank-config', {});
  },
  //Rule
  getListRule(params) {
    return ApiService.get(baseUrl,'rule', params );
  },
  createRule(params) {
    return ApiService.post(baseUrl + '/rule', params);
  },

  updateRule(id, params) {
    return ApiService.post(baseUrl + '/rule/' + id, params);
  },
  getRule(id) {
    return ApiService.get(baseUrl, 'rule/' + id, {});
  },

  deleteRule(id) {
    return ApiService.delete(baseUrl + '/rule/' + id, {});
  },
  getBanner() {
    return ApiService.get(baseUrl, 'setting/banner-config/' , {});
  },
  storeBanner(params) {
    return ApiService.post(baseUrl+'/setting/banner-config/' , params);
  },
  getGift() {
    return ApiService.get(baseUrl, 'setting/gift-config/' , {});
  },
  storeGift(params) {
    return ApiService.post(baseUrl+'/setting/gift-config/' , params);
  },
  //FaQ
  getListFaq(params) {
    return ApiService.get(baseUrl,'faq', params );
  },
  createFaq(params) {
    return ApiService.post(baseUrl + '/faq', params);
  },

  updateFaq(id, params) {
    return ApiService.post(baseUrl + '/faq/' + id, params);
  },
  getFaq(id) {
    return ApiService.get(baseUrl, 'faq/' + id, {});
  },

  deleteFaq(id) {
    return ApiService.delete(baseUrl + '/faq/' + id, {});
  },

  getClients() {
    return ApiService.post(baseUrl+'/combo-voucher/client');
  },
  reportRefers(params) {
    return ApiService.get(baseUrl, 'report/refer', params );
  },
  getReferDetail(id) {
    return ApiService.get(baseUrl, 'report/refer/'+id, );
  },

  //Quản lý trường học
  //getListChallenge
  getListUniversity(params) {
    return ApiService.get(baseUrl, 'university', params );
  },

  getUniversityDetail(id) {
    return ApiService.get(baseUrl, 'university/'+id);
  },

  createUniversity(params) {
    return ApiService.post(baseUrl + '/university/', params);
  },

  updateUniversity(id, params) {
    return ApiService.post(baseUrl + '/university/' + id, params);
  },

  //Import file truong hoc
  addFileUniversity(params) {
    return ApiService.post(baseUrl + "/university/import/import-file", params, {});
  },

  deleteUniversity(id) {
    return ApiService.delete(baseUrl + '/university/' + id, {});
  },

  getListReportStudent(params) {
    return ApiService.get(baseUrl, 'report/campaign-student', params);
  },

  getListReportReferStudent(params) {
    return ApiService.get(baseUrl, 'report/refer-students', params);
  },

  resendOtp(params) {
    return ApiService.post(baseUrl + '/vh-resend-otp', params);
  },

  createConfigSeeding(params) {
    return ApiService.post(baseUrl + '/user-seeding/create', params);
  },

  getUserSeedingDetail(id) {
    return ApiService.get(baseUrl, 'user-seeding/view/'+id);
  },

  updateConfigSeeding(id, params) {
    return ApiService.post(baseUrl + '/user-seeding/' + id, params);
  },

  getListAllCampaign(params) {
    return ApiService.get(baseUrl, 'get-campaign', params);
  },

  approveReferStudent(params) {
    return ApiService.post("/refer-student/approve", params, {});
  },

  addSeeding(params) {
    return ApiService.post("/refer-student/add-seeding", params, {});
  },

  
  // Challenge Rank
  getListChallengeRank(params) {
    return ApiService.get(baseUrl, 'challenge-rank', params );
  },

  getChallengeRankDetail(id) {
    return ApiService.get(baseUrl, 'challenge-rank/'+id);
  },

  createChallengeRank(params) {
    return ApiService.post(baseUrl + '/challenge-rank/', params);
  },

  updateChallengeRank(id, params) {
    return ApiService.post(baseUrl + '/challenge-rank/' + id, params);
  },

  deleteChallengeRank(id) {
    return ApiService.delete(baseUrl + '/challenge-rank/' + id, {});
  },


  getInfoCoupon(code) {
    return ApiService.get(baseUrl,'combo/get-info-coupon/'+code);
  },


};
