import ApiService from "../services/api.service";

const prefixList = "card-store";
const prefixExportCard = "card-store/export-card";
const prefixImport = "card-store/import-card";

export default {
    listCard(params) {
        return ApiService.get(prefixList, "list", params);
    },
    exportCard(params) {
        return ApiService.post(prefixExportCard, params);
    },
    importCard(params) {
        return ApiService.post(prefixImport, params);
    },
};
