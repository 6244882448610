import ApiService from "../services/api.service";

const prefix = "statistics";
const listRfm = "export";
const rfmFuntap = "rfm";
const baseUrl = "risk";
const urlGetList = "top-ten-users";
const urlGetListSituation = "situation";

export default {
  getListRfm(params) {
    return ApiService.get(prefix, listRfm, params);
  },
  getRfmFuntap(params) {
    return ApiService.post(prefix + "/" + rfmFuntap, params);
  },
  getListCoupon(params) {
    return ApiService.get("coupon", "", params);
  },
  getCouponAnalytics(params) {
    return ApiService.get("coupon", "analytics", params);
  },
  exportListCoupon(params) {
    return ApiService.get("coupon", "export", params);
  },
  getListTopTen(params) {
    return ApiService.get(baseUrl, urlGetList, params);
  },
  exportTopTen(params) {
    return ApiService.get(baseUrl, urlGetList + "/export", params);
  },
  getListSituation(params) {
    return ApiService.get(baseUrl, urlGetListSituation, params);
  },
  exportSituation(params) {
    return ApiService.get(baseUrl, urlGetListSituation + "/export", params);
  },
  getReportAccount() {
    return ApiService.get("report/account-balance", "");
  },
  getReportNewAccount(params) {
    return ApiService.get("report/new-account", "", params);
  },
};
