import TransactionRepository from "./transactionRepository";
import OrderRepository from "./transactionRepository";
import ReconcileRepository from "./reconcileRepository";
import BankRepository from "./bankRepository";
import StatsRepository from "./statsRepository";
import AccountRepository from "./accountRepository";
import cmsRepository from "./cmsRepository";
import WalletRunRepository from "./walletRunRepository";
import VoucherRepository from "@/core/repositories/voucherRepository";
import ReportRepository from "@/core/repositories/reportRepository";
import userRepository from "./userRepository";
import CampaignRepository from "@/core/repositories/CampaignRepository";
import ComboVoucher from "@/core/repositories/comboVoucher";
import FeeRepository from "@/core/repositories/FeeRepository";
import DirectionRepository from "@/core/repositories/directionRepository";
import BackendRepository from "@/core/repositories/backendRepository";
import LoyaltyRepository from "@/core/repositories/loyaltyRepository";
import BiRepository from "./biRepository";
import BlackListRepository from "@/core/repositories/BlackListRepository";
import RiskRepository from "@/core/repositories/riskRepository";
import EventRepository from "@/core/repositories/EventRepository";
import MonitorRopository from "@/core/repositories/monitorRepository";
import CardStoreRepository from "@/core/repositories/cardStoreRepository";

const repositories = {
  transactions: TransactionRepository,
  order: OrderRepository,
  reconcile: ReconcileRepository,
  bank: BankRepository,
  stats: StatsRepository,
  account: AccountRepository,
  cms: cmsRepository,
  WalletRun: WalletRunRepository,
  voucher: VoucherRepository,
  report: ReportRepository,
  user: userRepository,
  Campaign: CampaignRepository,
  comboVoucher: ComboVoucher,
  fee: FeeRepository,
  Backend: BackendRepository,
  direction: DirectionRepository,
  Bi: BiRepository,
  loyalty: LoyaltyRepository,
  blackList: BlackListRepository,
  risk: RiskRepository,
  event: EventRepository,
  monitor: MonitorRopository,
  cardStore: CardStoreRepository,
};

export default {
  get: (name) => repositories[name],
};
