import ApiService from "../services/api.service";

const prefix = "bank";
const listBank = "list-bank";
const addBinCode = "/add-bin-code";
const getListCreditCard = "credit-card/list";
const searchCreditCardInfo = "credit-card/search";
const getListTransactionByCard = "credit-card/list-transaction";
const searchTransaction = "credit-card/search-transaction";
const napasCashinDetail = "napas-cashin/inquire";
const listBankByPaymentMethod = "/list-trans-by-payment-method";

export default {
  getListBank(typeLink) {
    const params = {
      type_link: typeLink
    };
    return ApiService.get(prefix, listBank, params);
  },
  addBinBankCode(bankCode, bin, card_type, card_brand) {
    const params = {
      bank_code: bankCode,
      bin: bin,
      card_type: card_type,
      card_brand: card_brand,
    };
    return ApiService.post(prefix + addBinCode, params);
  },
  getListCreditCard(params) {
    return ApiService.get(prefix, getListCreditCard, params);
  },
  searchCreditCardInfo(params) {
    return ApiService.get(prefix, searchCreditCardInfo, params);
  },
  getListTransactionByCard(params) {
    return ApiService.get(prefix, getListTransactionByCard, params);
  },
  searchTransaction(params) {
    return ApiService.get(prefix, searchTransaction, params);
  },
  napasCashinDetail(params) {
    return ApiService.get(prefix, napasCashinDetail, params);
  },
  getListTransactionByPaymentMethod(params) {
    return ApiService.post(prefix + listBankByPaymentMethod, params);
  },
};
