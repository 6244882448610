import ApiService from "../services/api.service";

const urlConfigAds = 'top-service' ;

export default {
  getAll(params) {
    return ApiService.get(urlConfigAds, '',params);
  },
  show(id) {
    return ApiService.get(urlConfigAds+'/'+id);
  },

  update(id,params) {
    return ApiService.post(urlConfigAds+'/'+id,params);
  },

  delete(id) {
    return ApiService.delete(urlConfigAds+'/'+id);
  },

  analysis(id,params) {
    return ApiService.get(urlConfigAds,'analysis/'+id,params);
  },
  bot(id) {
    return ApiService.get(urlConfigAds,'bot/'+id);
  },
  storeBot(id,params) {
    return ApiService.post(urlConfigAds+'bot/'+id,params);
  },
  milestone(id) {
    return ApiService.get(urlConfigAds,'milestone/'+id);
  },
  storeMilestone(id,params) {
    return ApiService.post(urlConfigAds+'/milestone/'+id,params);
  },
  exportBxh(id,params) {
    return ApiService.get(urlConfigAds,'export/'+id,params);
  },
}
