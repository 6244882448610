import ApiService from "../services/api.service";

const prefix = "okr";
const available = prefix + "/available";
export default {
  listOkr(params) {
    return ApiService.get(prefix, "", params);
  },
  available() {
    return ApiService.get(available, "");
  },
  listServiceOkr() {
    return ApiService.get(prefix, "services");
  },
  updateOkr(id, params) {
    return ApiService.post(prefix + "/update/" + id, params);
  },
  detailOkr(id) {
    return ApiService.get(prefix + "/view/" + id);
  },
  createOkr(params) {
    return ApiService.post(prefix + "/create", params);
  }
};
