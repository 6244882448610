import ApiService from "../services/api.service";

const prefix = 'cms';
const urlConfigAds = prefix + "/ads";

export default {
  getAllDirection() {
    return ApiService.get(urlConfigAds, 'list-all-direction',{});
  },
}
