import ApiService from "../services/api.service";

const baseUrl = "wallet-run";
const listMission = "mission/listMission";
const storeMission = "/mission/store";
const updateMission = "/mission/update/";
const getOptions = "mission/getOptions";
const getMission = "mission/view/";
const deleteMission = "/mission/delete/";

////level
const listLevel = "level";
const getLevel = "level/view/";
const storeLevel = "/level/store";
const updateLevel = "/level/update/";
const deleteLevel = "/level/delete/";
const uploadMedia = "/media/upload";
//report
const reportActivities = "report/activities";
const reportMissions = "report/missions";
const reportBonusHistories = "report/bonus-histories";

export default {
  getList(params) {
    return ApiService.get(baseUrl, listMission, { params: params });
  },
  createMission(params) {
    return ApiService.post(baseUrl + storeMission, params);
  },

  updateMission(id, params) {
    return ApiService.post(baseUrl + updateMission + id, params);
  },

  getOptions() {
    return ApiService.get(baseUrl, getOptions, {});
  },
  getMission(id) {
    return ApiService.get(baseUrl, getMission + id, {});
  },

  deleteMission(id) {
    return ApiService.delete(baseUrl + deleteMission + id, {});
  },
  ///level

  getListLevel(params) {
    return ApiService.get(baseUrl, listLevel, params);
  },

  createLevel(params) {
    return ApiService.post(baseUrl + storeLevel, params);
  },

  uploadMedia(params) {
    const headers = { "Content-Type": "multipart/form-data" };
    return ApiService.post(uploadMedia, params, { headers });
  },

  updateLevel(id, params) {
    return ApiService.post(baseUrl + updateLevel + id, params);
  },

  deleteLevel(id) {
    return ApiService.delete(baseUrl + deleteLevel + id);
  },

  getLevel(id, params) {
    return ApiService.get(baseUrl, getLevel + id, { params: params });
  },

  getActivityHistories(params) {
    return ApiService.get(baseUrl, reportActivities, { params: params });
  },

  getMissionHistories(params) {
    return ApiService.get(baseUrl, reportMissions, { params: params });
  },

  getBonusHistories(params) {
    return ApiService.get(baseUrl, reportBonusHistories, { params: params });
  },
};
