import ApiService from "../services/api.service";

const prefix = "risk";
const notification = prefix + "/notification";
export default {
  getListConfigNotify(params) {
    return ApiService.get(notification, "", params);
  },
  updateConfigNotify(id, params) {
    return ApiService.post(notification + "/update/" + id, params);
  },
};
