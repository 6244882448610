import ApiService from "../services/api.service";

const baseUrl = "user";

const listUser = "list";
const addUser = baseUrl + "/add";

export default {
  createUser(params) {
    return ApiService.post(addUser, params);
  },
  getList(params){
    return ApiService.get(baseUrl, listUser, { params: params });
  }
};
