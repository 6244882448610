import ApiService from "../services/api.service";

const prefix = 'fee';
const report = "report";
const exportUrl = "report/export";

export default {
  report(params) {
    return ApiService.get(prefix, report, params);
  },
  exportData(params) {
    return ApiService.get(prefix, exportUrl, params);
  },
  feeDetail(params) {
    return ApiService.get(prefix, 'report/detail', params);
  },
}
