import ApiService from "../services/api.service";

const prefix = "charities";
export default {
  listOrganization(params) {
    return ApiService.get(prefix, 'organization/list', params);
  },
  showOrganization(id){
    return ApiService.get(prefix, 'organization/view/'+id);
  },
  deleteOrganization(id){
    return ApiService.delete(prefix+ '/organization/delete/'+id);
  },
  listCampaignCharities(params) {
    return ApiService.get(prefix, 'campaign/list', params);
  },
  showCampaignCharities(id){
    return ApiService.get(prefix, 'campaign/view/'+id);
  },
  deleteCampaignCharities(id){
    return ApiService.delete(prefix+ '/campaign/delete/'+id);
  },
  charityListUsers(params) {
    return ApiService.get(prefix, 'users', params);
  },
};
